h1 {
  @apply text_60 text-[#CDB972];
}

h3 {
  @apply text_24 text-[#161616] font-[500] mt-[40px];
}

p,
li {
  @apply text_16 text-[#2D2D2D] font-[400] mt-[16px];
}

ul {
  @apply pl-[20px];
}
li {
  @apply list-disc;
}
