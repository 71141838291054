.modalDiv {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 520px;
  height: fit-content;
  min-height: 100%;
}

.backdrop {
  background: rgba(170, 170, 170, 0.7); /* Reduced opacity */
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden; /* Prevent scrolling within backdrop */
}

.no-scroll {
  overflow: hidden;
}

