@tailwind base;
@tailwind components;

.button {
  @apply flex justify-center border  bg-transparent  px-6 py-4 gap-2.5 w-fit rounded-sm cursor-pointer
}

@tailwind utilities;

@font-face {
  font-family: "Big Caslon FB";
  src: url("./assets/fonts/big-caslon/BigCaslonFB-Bold.eot");
  src:
    local("./assets/fonts/big-caslon/Big Caslon FB Bold"),
    local("./assets/fonts/big-caslon/BigCaslonFB-Bold"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Bold.woff2") format("woff2"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Bold.woff") format("woff"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Big Caslon FB";
  src: url("./assets/fonts/big-caslon/BigCaslonFB-Regular.eot");
  src:
    local("./assets/fonts/big-caslon/Big Caslon FB Regular"),
    local("./assets/fonts/big-caslon/BigCaslonFB-Regular"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Regular.woff2") format("woff2"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Regular.woff") format("woff"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Big Caslon FB";
  src: url("./assets/fonts/big-caslon/BigCaslonFB-Black.eot");
  src:
    local("./assets/fonts/big-caslon/Big Caslon FB Black"),
    local("./assets/fonts/big-caslon/BigCaslonFB-Black"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Black.woff2") format("woff2"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Black.woff") format("woff"),
    url("./assets/fonts/big-caslon/BigCaslonFB-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --font-big-calson: "Big Caslon FB", sans-serif;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .widow-main {
    @apply w-full px-[16px] sm:px-[32px]  ;
  }

  .widow-inner {
    @apply w-full mx-auto max-w-[1200px]
     
    /* xl:w-[clamp(1200px,calc(1200/1440*100vw),1440px)] */
    /* xl:w-[clamp(1200px,calc(1200/1440*100vw),1750px)] */;
  }

  .styled_scrollbar::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
    height: 4px;
  }

.diamondShape::-webkit-scrollbar {
   display: none;
  }
  /* Define scrollbar handle */
  .styled_scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(9, 76, 59, 0.465);
    /* Color of the scrollbar handle */
    border-radius: 5px;
    /* Rounded corners of the scrollbar handle */
  }

  /* Define scrollbar track */
  .styled_scrollbar::-webkit-scrollbar-track {
    background-color: #dee8ea; /* Color of the scrollbar track */
  }

  .text_14 {
    @apply text-[14px] leading-[22px];
  }

  .text_16 {
    @apply text-[16px] leading-[24px];
  }

  .text_18 {
    @apply text-[16px] leading-[22px] xl:text-[18px] xl:leading-[26px];
  }

  .text_20 {
    @apply text-[18px] leading-[27px] xl:text-[20px] xl:leading-[30px];
  }

  .text_24 {
    @apply text-[20px] leading-[30px] sm:text-[22px] sm:leading-[32px] xl:text-[24px] xl:leading-[36px];
  }

  .text_28 {
    @apply text-[20px] leading-[30px] sm:text-[24px] sm:leading-[32px] xl:text-[28px] xl:leading-[36px];
  }
  .text_30 {
    @apply text-[28px] leading-[37px] xl:text-[30px] xl:leading-[42px];
  }

  .text_34 {
    @apply text-[30px] leading-[40px] sm:text-[34px] sm:leading-[42px];
  }

  .text_60 {
    @apply text-[48px] leading-[56px] xl:text-[60px] xl:leading-[64px] font-big-calson;
  }

  .text_48 {
    @apply text-[36px] leading-[48px] sm:text-[48px] sm:leading-[56px]  font-big-calson;
  }

  .text_96 {
    @apply text-[54px] leading-[62px] sm:text-[76px] sm:leading-[83px] lg:text-[96px] lg:leading-[103px] font-big-calson;
  }
}


.skiptranslate, .VIpgJd-ZVi9od-ORHb-OEVmcd{
  display: none !important;
}
.__className_54b096{
  top: 0px !important;
}
.ReactModal__Overlay{
  z-index: 10;
}
.input-field{
  @apply bg-gray-100 rounded-md min-h-10 px-3 py-1 focus:outline-none
}
label{
  @apply text-sm text-gray-500
}

.__className_f60719{
  top: 0px !important;
}

body{
  top: 0px !important;
}