.text-effect {
  @apply mx-auto  text-center max-w-[689px];

  background-image: linear-gradient(
    to right,
    #2f2f2f,
    #515151,
    #767676,
    #9d9d9d,
    #c6c6c6,
    #c7c7c7,
    #c8c8c8,
    #c9c9c9,
    #a3a3a3,
    #7e7e7e,
    #5b5b5b,
    #3a3a3a
  );
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}
